import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {ErrorPageComponent} from './components/error-page/error-page.component';
import {NotFoundPageComponent} from './components/not-found-page/not-found-page.component';
import {HomePageComponent} from './components/home-page/home-page.component';
import {ProjectStateService} from './services/project-state.service';
import {MainAntiStressComponent} from './components/main-anti-stress/main-anti-stress.component';
import {MainAntiSedentaryComponent} from './components/main-anti-sedentary/main-anti-sedentary.component';

const routes: Routes = [
  {path: 'ein-fehler-ist-aufgetreten', component: ErrorPageComponent},
  {path: 'keine-internetverbindung', component: ErrorPageComponent},
  {path: 'inhalt-nicht-gefunden-404', component: NotFoundPageComponent},
  {
    path: `de/${ProjectStateService.PROJECT_BASEPATH.STRESS_NAVIGATOR}`,
    component: MainAntiStressComponent,
    canActivate: [ProjectStateService],
    resolve: {content: ProjectStateService},
  },
  {
    path: `de/${ProjectStateService.PROJECT_BASEPATH.RAUS_UND_LOS}`,
    component: MainAntiSedentaryComponent,
    canActivate: [ProjectStateService],
    resolve: {content: ProjectStateService},
  },
  {path: '', component: HomePageComponent, canActivate: [ProjectStateService]},
  {path: '**', redirectTo: '/inhalt-nicht-gefunden-404'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
